import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';

import * as paths from "./route-paths";
import Home from '../pages/Home';
import AuthLayout from "../layouts/authLayout";
import Login from "../pages/Authentication/Login";
import Register from "../pages/Authentication/Register";
import NotFound from '../components/NotFound'
import Movies from "../pages/Movie";
import Popular from "../pages/popular";
import MovieView from "../pages/details";
import App from '../pages/App';
import KoreanMovies from '../pages/koreanMovies';
import ForengMovies from '../pages/foreingMovies';
import AnimationPage from '../pages/animation';
import Drama from '../pages/drama';
import Entertainment from '../pages/entertainment';

const Router = createBrowserRouter([
    {
        element:<App />,
        children:[
            {
              path: paths.HOME,
              element: <Home />,
            },
            {
              path: paths.MOVIE,
              element: <Movies />,
            },
            {
              path: paths.MOVIEDETAILS,
              element: <MovieView />,
            },
            {
              path: paths.koreanMovies,
              element: <KoreanMovies />,
            },
            {
              path: paths.Animation,
              element: <AnimationPage />,
            },
            {
              path: paths.forengMovies,
              element: <ForengMovies />,
            },
            {
              path: paths.POPULAR,
              element: <Popular />,
            },
            {
              path: paths.drama,
              element: <Drama />,
            },
            {
              path:paths.Entertainment,
              element: <Entertainment />,
            },
            
            {
              path: paths.NOTFOUND,
              element: <NotFound />,
            },
        ]
    },
    {
      path: '*',
      element: <Navigate to="/404" />,
    },

    {
      element: <AuthLayout />,
      children: [
        {
          path: paths.LOGIN,
          element: <Login />,
        },
        {
          path: paths.REGISTER,
          element: <Register />,
        },
      ],
    },
  ]);
  export default Router
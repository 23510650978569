import Pagination from "../../components/Pagination";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/ErrorMessage";
import MovieCards from "../../components/MovieCards";
import { useState } from "react";
import MoviesHeader from "../../components/movieHeader";
import { useAllmovies } from "../../hooks/hookAllmovies";

function Movies() {
  const {
    data,
    isLoading,
    error,
    setPage,
    pageCount,
    refetch,
    moviesFilters,
    setMoviesFilters,
  } = useAllmovies();
  const [bannerData, setBannerData] = useState([]);

  const renderMovies = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (error) {
      return <ErrorMessage refetch={refetch} />;
    }

    if (!Array.isArray(data)) {
      return <div>No movies available</div>; // Fallback UI if data is not an array
    }

    return (
      <ul className="movie-list">
        {data.map((movie) => (
          <MovieCards
            key={movie.title_id}
            title={movie.title}
            id={movie.title_id}
            image={movie.image}
            poster={movie.url_movie}
            created_at={movie.createdDate}
          />
        ))}
      </ul>
    );
  };
  return (
    <div className="content">
      <div className="content-banner"></div>
      <div id="movie-container">
        <MoviesHeader
          refetch={refetch}
          moviesFilters={moviesFilters}
          setMoviesFilters={setMoviesFilters}
        />
        {renderMovies()}
      </div>
      <Pagination setPage={setPage} pageCount={pageCount} />
    </div>
  );
}

export default Movies;

import React, { useState, useEffect, useContext } from "react";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark,
  faHeart,
  faLandMineOn,
  faSearch,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { useMovieDetail } from "../../hooks/viewMovies";
import VideoPlayerReact from "../../components/videos_player/videoPlayer";
import { authContext } from "../../contexts/auth-provider";
import { TestingBunnyApi } from "../../hooks/testingBunny";
import Loading from "../../components/Loading";
import Epesod from "../../components/ep/epoesond";
import { ActorSlider } from "../../components/Artor_slider";
const MovieView = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { token } = useContext(authContext);
  const fullUrl = `${window.location.origin}${currentPath}`;
  const { data: movieData, isLoading, error, refetch } = useMovieDetail();
  const { data: videoServer } = TestingBunnyApi();
  const [addBookMark, setBookMark] = useState(false);
  const [fevorate, setFevorate] = useState(false);
  const [report, setReport] = useState(false);
  const [fevorateCount, setFevorateCount] = useState(0);
  const [filter, setFilter] = useState("");
  useEffect(() => {
    const swiper = new Swiper(".swiper-free-start", {
      freeMode: true,
      slidesPerView: "auto",
      spaceBetween: 0,
    });

    const movieFrame = document.getElementById("movie-frame");
    if (movieFrame) {
      movieFrame.onload = () => {
        setTimeout(() => {
          movieFrame.style.display = "block";
        }, 1000);
      };
    }
  }, []);

  const handleAddbookMark = (status) => {
    setBookMark(status);
  };

  const handleFevorate = (status) => {
    setFevorateCount(
      !status && fevorateCount > 0 ? fevorateCount - 1 : fevorateCount + 1
    );
    setFevorate(status);
  };

  const handleReport = (status) => {
    setReport(status);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };
  const executeGood = (href, setCount) => {
    axios
      .post(href, { js: "on" })
      .then((res) => {
        if (res.data.error !== 0) {
          alert(res.data.message);
        } else if (res.data.count) {
          setCount(res.data.count);
          alert("이 글을 추천하였습니다.");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  let actors = [];
  if (typeof movieData?.actor === "string") {
    if (
      movieData.actor.trim().startsWith("[") &&
      movieData.actor.trim().endsWith("]")
    ) {
      try {
        actors = JSON.parse(movieData.actor);
      } catch (error) {
        actors = [];
      }
    } else {
      // handle case where actor data is not a valid JSON string
    }
  } else if (Array.isArray(movieData?.actor)) {
    actors = movieData.actor;
  }
  // const renderMovies = () => {
  //     return (
  //         <ul className="movie-list">{
  //             data?.map(movie => {
  //                 return (
  //                     <MovieCards
  //                         key={movie.title_id}
  //                         title={movie.title}
  //                         id={movie.title_id}
  //                         ep={movie.number_ep}
  //                         image={movie.image}
  //                         poster={movie.url_movie}
  //                         created_at={movie.createdDate}
  //                     />
  //                 );
  //             })
  //         }
  //         </ul>
  //     )

  // }
  const handleShare = () => {
    const shareOptions = `
      width=600,
      height=400,
      left=${window.innerWidth / 2 - 300},
      top=${window.innerHeight / 2 - 200}
    `;

    const shareWindow = window.open("", "", shareOptions);
    shareWindow.document.write(`
      <html>
        <head>
          <title>${process.env.REACT_APP_NAME}/${fullUrl}</title>
        </head>
        <body style="background-color:black;">
          <h2 style="color:#5E0B0B; text-align:center;">Share this page</h2>
          <ul style="width:100%; display:flex; flex-direction:row; align-items:center; justify-content:space-around;">
            <li><a href="https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              fullUrl
            )}" target="_blank">Facebook</a></li>
            <li><a href="https://twitter.com/intent/tweet?url=${encodeURIComponent(
              fullUrl
            )}&text=Check out this awesome page!" target="_blank">Twitter</a></li>
            <li><a href="#" onclick="alert('Instagram does not support direct sharing. Please copy the link: ${fullUrl}')">Instagram</a></li>
          </ul>
        </body>
      </html>
    `);
  };

  return (
    <div className="content">
      <div className="player"></div>
      <div id="movie-container">
        <div className="movie-view">
          <div className="movie-player">
            {!movieData?.bunny_url ? (
              <div
                style={{
                  color: "red",
                  width: "100%",
                  fontWeight: "600",
                  height: "350px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "2px solid gray",
                }}
              >
                <h2>Video url not found!</h2>
              </div>
            ) : (
              <VideoPlayerReact src={`${movieData?.bunny_url}`} />
            )}
            {/* <VideoPlayerReact src={`https://vz-f75d85eb-5e7.b-cdn.net/${videoTarget}/playlist.m3u8`} /> */}
          </div>
          <div className="content-banner"></div>
          <div className="movie-header">
            <div className="movie-utility">
              {token ? (
                <button onClick={() => handleFevorate(!fevorate)}>
                  <FontAwesomeIcon
                    icon={faHeart}
                    className="icons"
                    style={{ color: fevorate && "green" }}
                  />
                  <span>
                    좋아요<em> {fevorateCount}</em>
                  </span>
                </button>
              ) : (
                ""
              )}
              <button onClick={handleShare}>
                <FontAwesomeIcon icon={faShareNodes} className="icons" />
                <span>알림</span>
              </button>

              {token ? (
                <button onClick={() => handleAddbookMark(!addBookMark)}>
                  <FontAwesomeIcon
                    icon={faBookmark}
                    className="icons"
                    style={{ color: addBookMark && "green" }}
                  />
                  <span>신고</span>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="movie-posted">
            <h1 style={{ padding: "20px 0" }}>{movieData?.title}</h1>
            {movieData?.number_ep && movieData?.number_ep >= 1 ? (
              <Epesod
                title_id={movieData?.title_id}
                current_ep={movieData?.number_ep}
              />
            ) : (
              ""
            )}
            <div className="posted">
              <div className="summary">
                <h3>줄거리</h3>
                <b className="tag">
                  {movieData?.author && "감독: " + movieData?.author}
                </b>
                <div className="">
                  {Array.isArray(actors) && actors.length > 0 ? (
                    <ActorSlider actors={movieData?.actor_images} />
                  ) : (
                    <span className="tag" style={{ color: "white" }}>
                      {movieData?.actor}
                    </span>
                  )}
                </div>
              </div>
              <div className="overview">
                <p>{movieData?.des && movieData?.des}</p>
              </div>
            </div>
          </div>
          {/* <div className="related" style={{ marginBottom: '0px', padding:'10px' }}>
            <div className="search-bar">
              <div className="search-input">
                <input type="text" style={{color:'white'}} placeholder="Search..." value={filter} onChange={handleFilterChange} />
                <button type="button" onClick={() => executeGood(`https://api.example.com/search?q=${filter}`, setFevorateCount)}>
                  <FontAwesomeIcon icon={faSearch} className='icons' style={{color:'white'}} />
                </button>
              </div>
            </div>
            <div className="related-posts">
              {/* {renderMovies()} 
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MovieView;

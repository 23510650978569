import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.location.href = "https://www.google.com/404";
  }, []);
  return null;
};

export default NotFound;
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import NotFoundImg from '../../assets/logo/Server proccesing.png';
export const ActorSlider = ({ actors }) => {
  const renderActor = (name, imageUrl) => {
    return (
      <SwiperSlide key={name}>
        <div style={{ position: 'relative', overflowY: 'hidden' }}>
          <img src={imageUrl || NotFoundImg} style={{ width: '100%', height: '250px' }} alt={name} />
          <div style={{ textAlign: 'center', paddingTop: '10px' }}>
            <h3>{name}</h3>
          </div>
        </div>
      </SwiperSlide>
    );
  };

  return (
    <Swiper
      modules={[Navigation, Pagination, A11y]}
      spaceBetween={10}
      slidesPerView={6}
      style={{ padding: '20px 0' }}
      breakpoints={{
        1100: {
          slidesPerView: 6,
        },
        800: {
          slidesPerView: 4,
        },
        600: {
          slidesPerView: 3,
        },
        250: {
          slidesPerView: 2,
        }
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log('slide change')}
    >
      {actors && Object.entries(actors).map(([name, imageUrl]) => renderActor(name, imageUrl))}
    </Swiper>
  );
};
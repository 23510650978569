import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { httpClient } from "../services/Http";

export const HookSearch = () => {
    const [search, setSearch] = useState(null)

    const fetchMovies = async (search) => {
        try {
            const response = await httpClient.get(`search?${search? `search=${search}`:''}`,)
            return response.data;
          } catch (error) {
            // Handle errors here
            // console.error('Error:', error);
            throw error;
          }
    }

    const onSuccess = (data) => {
        console.log("fetch succeeded!", data.data)
    }

    const onError = (error) => {
        // console.log("fetch failed!", error)
    }
    
    const { data, isLoading, error, isError, refetch } = useQuery(
        {
            queryKey: ["movies", search],
            queryFn: () => fetchMovies(search),
            staleTime: 1000,
            onSuccess: onSuccess,
            onError: onError,
        }
    )
    return {data, isLoading, error, search, setSearch, refetch}
}
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { authContext } from "../../contexts/auth-provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import "../../assets/css/menue_icon.css";
import { HookSearch } from "../../hooks/useHookSearch";
const Header = ({ setIsMenueActive, isMenueActive }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const inputRef = useRef(null);
  const { handleModalLogin, handleModalRegister, logged, token, setLogged } =
    useContext(authContext);
  const { data, isLoading, error, search, setSearch, refetch } = HookSearch();
  const [searchValue, setSearchValue] = useState(search ? search : null);
  const [isSearchModle, setSearchModle] = useState(false);
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleShowingSearchModle = () => {
    setSearchModle(true);
  };
  const handleClearSearchValue = () => {
    setSearch(null);
    setSearchModle(false);
    inputRef.current.value = null;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setSearchModle(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setLogged(false);
    window.location.reload();
  };

  const menuIconRef = useRef(null);

  const hanldleShowingMenue = () => {
    setIsMenueActive(!isMenueActive);
  };

  const handleClickOutside = (event) => {
    if (menuIconRef.current && !menuIconRef.current.contains(event.target)) {
      setIsMenueActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div id="app">
      <div id="header">
        <div className="col-logo">
          <div className="logo-inner">
            <div
              className="menuicon__wrapper"
              ref={menuIconRef}
              onClick={hanldleShowingMenue}
            >
              <div className={`menuicon ${isMenueActive ? "active" : ""}`}>
                <span className="menuicon__dot"></span>
                <span className="menuicon__dot"></span>
                <span className="menuicon__dot"></span>
                <span className="menuicon__dot"></span>
                <span className="menuicon__dot"></span>
                <span className="menuicon__dot"></span>
                <span className="menuicon__dot"></span>
                <span className="menuicon__dot"></span>
                <span className="menuicon__dot"></span>
              </div>
            </div>
            <div className="logo">
              <h1>
                <Link to="/" title="누누티비">
                  <img
                    src={require("../../assets/logo/moomoo.png")}
                    alt="누누티비"
                    title=""
                    width={"100px"}
                  />
                </Link>
              </h1>
            </div>
          </div>
        </div>
        <div className="col-search">
          <div className="search-inner">
            <div className="searchWrapper" id="search">
              <div className="search-form">
                <input
                  type="text"
                  ref={inputRef}
                  onChange={(e) => handleSearch(e)}
                  onClick={handleShowingSearchModle}
                  className="search-input"
                  placeholder="검색어를 입력해주세요"
                />
                {!search && (
                  <div className="search-res">
                    <button className="submit" type="button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                        data-icon="material-symbols:search"
                        className="iconify iconify--material-symbols"
                      >
                        <path
                          fill="currentColor"
                          d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5t1.888-4.612T9.5 3t4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5t-1.312-3.187T9.5 5T6.313 6.313T5 9.5t1.313 3.188T9.5 14"
                        ></path>
                      </svg>
                    </button>
                  </div>
                )}
                {search && (
                  <div className="search-res">
                    <button
                      className="submit"
                      type="button"
                      style={{ color: "white" }}
                      onClick={handleClearSearchValue}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`search_resul ${
                isSearchModle ? "active" : "inActive"
              }`}
            >
              <ul>
                {data?.length === 0 && (
                  <li
                    style={{
                      color: "red",
                      fontWeight: "900",
                      fontSize: "1.5rem",
                      textAlign: "center",
                    }}
                  >
                    찾을 수 없습니다!
                  </li>
                )}
                {data?.map((value, index) => {
                  return (
                    <li key={index + 1}>
                      <Link
                        key={index + 1}
                        to={`/moviedetails/${value.id}/${value.title}/0`}
                      >
                        {value.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        {token ? (
          <div className="col-extra">
            <div className="extra-inner">
              <div className="member-action" onClick={handleLogout}>
                <span style={{ color: "#7D0102" }}>
                  <FontAwesomeIcon icon={faPowerOff} />
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-extra">
            <div className="extra-inner">
              <div className="member-action">
                <span onClick={handleModalLogin}>로그인</span>
                <span onClick={handleModalRegister}>회원가입</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div id="aside" className={`${isMenueActive && "menue_active"}`}>
        <div
          id="scrolling-box"
          className="scrolling-box mb-container"
          style={{ position: "relative" }}
        >
          <div className="mb-track mb-track-x">
            <div className="mb-bar" style={{ width: "194px", left: "0" }}></div>
          </div>
          <div className="mb-track mb-track-y">
            <div className="mb-bar" style={{ height: "829px" }}></div>
          </div>
          <div
            className="mb-content"
            style={{ overflowY: "auto", marginRight: "-17px" }}
          >
            <div className="home-menu">
              <div className="rows mobile">
                <Link to="/" title="누누티비">
                  <img
                    src={require("../../assets/logo/moomoo.png")}
                    alt="누누티비"
                    title=""
                    width={"100px"}
                  />
                </Link>
              </div>
              <div className="rows">
                <Link to="/" className={`${currentPath === "/" && "active"}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                    data-icon="material-symbols:home-outline"
                    className="iconify iconify--material-symbols"
                  >
                    <path
                      fill="currentColor"
                      d="M6 19h3v-6h6v6h3v-9l-6-4.5L6 10zm-2 2V9l8-6l8 6v12h-7v-6h-2v6zm8-8.75"
                    ></path>
                  </svg>
                  <strong>홈</strong>
                </Link>
              </div>
              <div className="rows">
                <Link
                  to="/movies"
                  className={`${currentPath === "/movies" && "active"}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                    data-icon="ic:round-list-alt"
                    className="iconify iconify--ic"
                  >
                    <path
                      fill="currentColor"
                      d="M12 9h4c.55 0 1-.45 1-1s-.45-1-1-1h-4c-.55 0-1 .45-1 1s.45 1 1 1m0 4h4c.55 0 1-.45 1-1s-.45-1-1-1h-4c-.55 0-1 .45-1 1s.45 1 1 1m0 4h4c.55 0 1-.45 1-1s-.45-1-1-1h-4c-.55 0-1 .45-1 1s.45 1 1 1M7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7zM20 3H4c-.55 0-1 .45-1 1v16c0 .55.45 1 1 1h16c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1m-1 16H5V5h14z"
                    ></path>
                  </svg>
                  <strong>전체보기</strong>
                </Link>
              </div>
              <div className="rows">
                <Link
                  to="#"
                  onClick={(e) => e.preventDefault()}
                  className={`${currentPath === "/popular" ? "active" : ""}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                    data-icon="ic:outline-star-outline"
                    className="iconify iconify--ic"
                  >
                    <path
                      fill="currentColor"
                      d="m22 9.24l-7.19-.62L12 2L9.19 8.63L2 9.24l5.46 4.73L5.82 21L12 17.27L18.18 21l-1.63-7.03zM12 15.4l-3.76 2.27l1-4.28l-3.32-2.88l4.38-.38L12 6.1l1.71 4.04l4.38.38l-3.32 2.88l1 4.28z"
                    ></path>
                  </svg>
                  <strong style={{ color: "#514d4d" }}>인기영상</strong>
                </Link>
              </div>
            </div>
            <div className="main-menu">
              <div className="menu-list">
                <div className="menu">
                  <h2>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      data-icon="ic:outline-local-movies"
                      className="iconify iconify--ic"
                    >
                      <path
                        fill="currentColor"
                        d="M14 5v14h-4V5zm6-2h-2v2h-2V3H8v2H6V3H4v18h2v-2h2v2h8v-2h2v2h2zm-4 6V7h2v2zM6 9V7h2v2zm10 4v-2h2v2zM6 13v-2h2v2zm10 4v-2h2v2zM6 17v-2h2v2z"
                      ></path>
                    </svg>
                    <strong>영화</strong>
                  </h2>
                  <ul>
                    <li>
                      <Link
                        to="/korean movie"
                        className={`${
                          currentPath === "/korean movie" && "active"
                        }`}
                      >
                        한국영화
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/foreign movies"
                        className={`${
                          currentPath === "/foreign movies" && "active"
                        }`}
                      >
                        {" "}
                        해외영화{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/theatrical version animation"
                        className={`${
                          currentPath === "/theatrical version animation" &&
                          "active"
                        }`}
                      >
                        {" "}
                        [극장판] 에니메이션{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="menu-list">
                <div className="menu">
                  <h2>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      data-icon="material-symbols:tv-gen-outline"
                      className="iconify iconify--material-symbols"
                    >
                      <path
                        fill="currentColor"
                        d="M4 21v-2q-.825 0-1.412-.587T2 17V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v11q0 .825-.587 1.413T20 19v2h-1l-.65-2H5.675L5 21zm0-4h16V6H4zm8-5.5"
                      ></path>
                    </svg>
                    <strong>프로그램</strong>
                  </h2>
                  <ul>
                    <li>
                      <Link
                        to="/drama"
                        className={`${currentPath === "/drama" && "active"}`}
                      >
                        드라마
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/entertainment-program"
                        className={`${
                          currentPath === "/entertainment-program" && "active"
                        }`}
                      >
                        예능프로그램
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="menu-list authentication">
                <div className="menu">
                  <h2>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      data-icon="mdi:film-star-outline"
                      className="iconify iconify--mdi"
                    >
                      <path
                        fill="currentColor"
                        d="M13 19c0 .34.04.67.09 1H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1l2 4h3L8 4h2l2 4h3l-2-4h2l2 4h3l-2-4h4v9.81c-.61-.35-1.28-.59-2-.72V10H5.76L4 6.47V18h9.09c-.05.33-.09.66-.09 1m10-1.11l-2.89-.25L19 15l-1.13 2.64l-2.87.25l2.18 1.88l-.68 2.81l2.5-1.49l2.45 1.49l-.65-2.81z"
                      ></path>
                    </svg>
                    <strong>Authentication</strong>
                  </h2>
                  <ul>
                    {token ? (
                      <li>
                        <Link onClick={handleLogout}>
                          <span style={{ color: "#7D0102" }}>
                            <FontAwesomeIcon icon={faPowerOff} />
                          </span>
                        </Link>
                      </li>
                    ) : (
                      <>
                        <li>
                          <Link
                            onClick={handleModalLogin}
                            className={`${
                              currentPath === "/login" && "active"
                            }`}
                          >
                            <span>로그인</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={handleModalRegister}
                            className={`${
                              currentPath === "/register" && "active"
                            }`}
                          >
                            <span>회원가입</span>
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { httpClient } from "../services/Http";

export const useMovies = () => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState(null);
  const [moviesFilters, setMoviesFilters] = useState(null);
  const fetchMovies = async (page, moviesFilters, search) => {
    try {
      const response = await httpClient.get(`movies/category/in-cate`);
      setPageCount(response.data.last_page);
      setPage(response.data.current_page);
      return response.data["category"];
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
      throw error;
    }
  };

  const onSuccess = (data) => {
    //console.log("fetch succeeded!", data.data)
  };

  const onError = (error) => {
    //console.log("fetch failed!", error)
  };

  const { data, isLoading, error, isError, refetch } = useQuery({
    queryKey: ["movies", page, moviesFilters, search],
    queryFn: () => fetchMovies(page, moviesFilters, search),
    staleTime: 1000,
    onSuccess: onSuccess,
    onError: onError,
  });
  return {
    data,
    isLoading,
    error,
    moviesFilters,
    search,
    setSearch,
    setMoviesFilters,
    setPage,
    pageCount,
    refetch,
  };
};

import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { httpClientBunny } from "../services/Http/BunnyApi";

export const TestingBunnyApi = () => {
    const [video_uid, setvideo_uid] = useState(null)
    const fetchMovies = async () => {
        try {
            const response = await httpClientBunny.get(`library/251965/videos?page=1&itemsPerPage=2147483647`)
            //console.log(response)
            return response.data.items; 
          } catch (error) {
            // Handle errors here
            console.error('Error:', error);
            throw error;
          }
    }

    const onSuccess = (data) => {
        console.log("fetch succeeded!", data.data)
    }

    const onError = (error) => {
        console.log("fetch failed!", error)
    }
    
    const { data, isLoading, error, isError, refetch } = useQuery(
        {
            queryKey: ["movies"],
            queryFn: () => fetchMovies(),
            staleTime: 1000,
            onSuccess: onSuccess,
            onError: onError,
        }
    )

    return {data, isLoading, error, video_uid, setvideo_uid, refetch}
}
import { useMovies } from "../../hooks/useMovies";
import Pagination from "../../components/Pagination";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/ErrorMessage";
import MoviesHeader from "../../components/movieHeader";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function Home() {
  const {
    data,
    isLoading,
    error,
    moviesFilters,
    search,
    setSearch,
    setMoviesFilters,
    setPage,
    pageCount,
    refetch,
  } = useMovies();

  const translateCategory = (category) => {
    switch (category) {
      case "k-drama":
        return "드라마";
      case "k-movies":
        return "한국영화";
      case "foreign":
        return "외국영화";
      case "animation-movies":
        return "애니메이션";
      case "entertainment":
        return "예능/시사";
      // Add more translations as needed
      default:
        return category;
    }
  };

  const translateCategoryPath = (category) => {
    switch (category) {
      case "k-drama":
        return "/drama";
      case "k-movies":
        return "/korean-movies";
      case "foreign":
        return "/foreign-movies";
      case "animation-movies":
        return "/theatrical-version-animation";
      case "entertainment":
        return "/entertainment-program";
      // Add more path translations as needed
      default:
        return `/${category}`;
    }
  };

  const renderMovies = (category) => {
    const movies = data[category];
    if (!Array.isArray(movies)) {
      return null; // or some fallback UI
    }

    return movies.map((movie) => (
      <SwiperSlide key={movie.title_id}>
        <div style={{ position: "relative", overflowY: "hidden" }}>
          <Link
            to={`/moviedetails/${movie.title_id}/${movie.title}/${
              movie.number_ep ? movie.number_ep : 0
            }`}
            className="thumb"
          >
            <img
              src={movie.image}
              style={{ width: "100%", height: "250px" }}
              alt={movie.title}
            />
          </Link>
          <div style={{ textAlign: "center", paddingTop: "10px" }}>
            <h3>{movie.title}</h3>
          </div>
        </div>
      </SwiperSlide>
    ));
  };

  const renderCategories = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (error) {
      return <ErrorMessage refetch={refetch} />;
    }

    if (!data || typeof data !== "object") {
      return null; // or some fallback UI
    }

    return Object.keys(data).map((category) => (
      <div key={category}>
        <button className="px-5 py-1 bg-white hover:bg-transparent">
          <Link to={translateCategoryPath(category)}>
            {translateCategory(category)}
          </Link>
        </button>
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={10}
          slidesPerView={6}
          style={{ padding: "20px 0" }}
          breakpoints={{
            1100: {
              slidesPerView: 6,
            },
            800: {
              slidesPerView: 4,
            },
            600: {
              slidesPerView: 3,
            },
            250: {
              slidesPerView: 2,
            },
          }}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          {renderMovies(category)}
        </Swiper>
      </div>
    ));
  };

  return (
    <div className="content">
      <div className="content-banner"></div>
      <div id="movie-container">{renderCategories()}</div>
    </div>
  );
}

export default Home;

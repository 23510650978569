export const HOME = "/";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const MOVIE = "/movies";
export const POPULAR = "/popular";
export const MOOMOOM = "/moomoom/:movietype";
export const HISTROY = "/history";
export const MOVIEDETAILS = "/moviedetails/:id/:title/:ep";
export const koreanMovies = "/korean movie";
export const forengMovies = "/foreign movies";
export const Animation = "/theatrical version animation";
export const Entertainment = "/entertainment-program";
export const drama = "/drama";

export const NOTFOUND = "/404";

import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <div id="footer">
            <div className="notice-content">
                <div className="inner">
                </div>
            </div>
            <div className="footer-content">
                <div className="link-list">
                    <Link to="https://nunutv.me/all/program_list.php#" className="liner"><span>사이트 소개</span></Link>
                    <Link to="https://nunutv.me/all/program_list.php#" className="liner"><span>이용약관</span></Link>
                    <Link to="https://nunutv.me/all/program_list.php#" className="liner"><span>개인정보 취급방침</span></Link>
                    <Link to="https://nunutv.me/all/program_list.php#" className="liner"><span>고객센터</span></Link>
                    <Link to="https://nunutv.me/all/program_list.php#" rel="modal:open nofollow" className="liner"><span>자료요청</span></Link>
                    <Link to="https://nunutv.me/?device=mobile" className="liner" rel="nofollow"><span>모바일 버전</span></Link>
                    <Link to="https://nunutv.me/all/program_list.php#" className="liner" rel="nofollow"><span>저작권 보호센터</span></Link>
                </div>
                <div className="copyright">
                   <p>
                         본 사이트는 링크 제공 사이트 이며, 그 어떠한 동영상, 파일등을 직접적으로 업로드 제공을 하지 않습니다. 

                        웹서핑을 통하여 각종 홈페이지등의 하이퍼링크, 다이렉트링크, 직접 링크 등으로 링크된 페이지 주소만을 수집 저장하여 제공하고 있습니다. 

                        해당 사이트에서 제공 되는 콘텐츠는 링크 된 컨텐츠이며 각각 연결된 페이지등은 그 페이지에서 제공하는 "서버, 사이트"의 상황에 따라 페이와 내용이 삭제 또는 중단 될 수 있으며 

                        본 사이트와 어떠한 관련이 없음을 알려드립니다. 
                   </p>
                </div>
            </div>
        </div>
    )
}

export default Footer
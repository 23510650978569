import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ImageNotFound from '../../assets/logo/Server proccesing.png';
import PlaceholderImage from '../../assets/logo/loading_setting_bg.gif';
import './style.css';

const MovieCards = ({ id, title, image, ep, created_at }) => {
    const [imageSrc, setImageSrc] = useState(PlaceholderImage);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        // Ensure the image URL starts with https
        const validImageUrl = image?.startsWith('http') ? image?.replace('http://', 'https://') : `https://${image}`;
        setImageSrc(validImageUrl);
    }, [image]);

    // Function to handle image load success
    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    // Function to handle image load errors
    const handleImageError = () => {
        setImageSrc(ImageNotFound);
    };

    return (
        <li>
            <Link to={`/moviedetails/${id}/${title}/${ep?ep:0}`} className="thumb">
                <div className="poster">
                    <img
                        src={imageLoaded ? imageSrc : PlaceholderImage}
                        alt={title}
                        onLoad={handleImageLoad}
                        onError={handleImageError}
                    />
                </div>
            </Link>
            <div className="title">
                <Link to={`/movies/detail/${title}`}>
                    <strong>{title}</strong>
                </Link>
            </div>
        </li>
    );
};

export default MovieCards;

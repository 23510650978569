import { useMovies } from "../../hooks/useMovies";
import Pagination from "../../components/Pagination";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/ErrorMessage";
import MovieCards from "../../components/MovieCards";
import MoviesHeader from "../../components/movieHeader";

function Popular(){
    const { data, isLoading, error, setPage, pageCount, refetch, moviesFilters, setMoviesFilters, } = useMovies()
    // const [bannerData, setBannerData]  = useState([])
    const renderMovies = () => {
        if (isLoading) {
            return <Loading />
        }
        
        if (error) {
            return <ErrorMessage refetch={refetch} />
        }
        return (
            <ul className="movie-list">{
                data?.map(movie => {
                    return (
                        <MovieCards
                            key={movie.title_id}
                            title={movie.title}
                            id={movie.title_id}
                            image={movie.image}
                            poster={movie.url_movie}
                            created_at={movie.createdDate}
                        />
                    );
                })
            }
            </ul>
        )
        
    }

    return (
        <div className="content">
            <div className="content-banner">
            </div>
            <div id="movie-container">
                <MoviesHeader refetch={refetch} moviesFilters={moviesFilters} setMoviesFilters={setMoviesFilters}/>
                { renderMovies() }
            </div>
            <Pagination
                setPage={setPage}  
                pageCount={pageCount}
           />
        </div>
    )
}

export default Popular
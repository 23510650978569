import React, { useRef, useEffect, useState } from 'react';
import Hls from 'hls.js';

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    // const disableInspect = () => {
    //   document.addEventListener('contextmenu', function (e) {
    //     e.preventDefault();
    //   }, false);

    //   document.addEventListener('keydown', function (e) {
    //     if (e.keyCode === 123 || 
    //         (e.ctrlKey && e.shiftKey && (e.keyCode === 73 || e.keyCode === 74)) || 
    //         (e.ctrlKey && e.keyCode === 85)) {
    //       e.preventDefault();
    //       return false;
    //     }
    //   }, false);
    // };

    // disableInspect();

    let hls;

    const handleError = (data) => {
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            console.error('Network error:', data);
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.error('Media error:', data);
            break;
          default:
            console.error('Other error:', data);
            break;
        }
        setError(true);
      }
    };

    if (videoRef.current) {
      if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(videoRef.current);
        hls.on(Hls.Events.ERROR, handleError);
      } else if (videoRef.current.canPlayType('application/x-mpegURL')) {
        videoRef.current.src = src;
        videoRef.current.addEventListener('error', () => setError(true));
      } else {
        setError(true);
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [src]);

  return (
    <div style={{ width: '100%' }}>
      {error ? (
        <div style={{ color: 'red', textAlign: 'center' }}>Video has been removed or not found!</div>
      ) : (
        <video
          ref={videoRef}
          loading="lazy"
          controls
          style={{ width: '100%', height: 'auto' }}
          preload="metadata"
          controlsList="nodownload"
          tabIndex="-1"
        >
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

export default VideoPlayer;

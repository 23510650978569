
import Pagination from "../../components/Pagination";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/ErrorMessage";
import MovieCards from "../../components/MovieCards";
import MoviesHeader from "../../components/movieHeader";
import { UseHookEntertainment } from "../../hooks/hookEntertainment";

function Entertainment(){
    const {data, isLoading, error, moviesFilters, search, setSearch, setMoviesFilters, setPage, pageCount, refetch} = UseHookEntertainment()

    const renderMovies = () => {
        if (isLoading) {
            return <Loading />
        }
        
        if (error) {
            return <ErrorMessage refetch={refetch} />
        }
        return (
            <ul className="movie-list">{
                data?.map(movie => {
                    return (
                        <MovieCards
                            key={movie.title_id}
                            title={movie.title}
                            id={movie.title_id}
                            ep={movie.number_ep}
                            image={movie.image}
                            poster={movie.url_movie}
                            created_at={movie.createdDate}
                        />
                    );
                })
            }
            </ul>
        )
        
    }

    return (
        <div className="content">
                <div className="content-banner">
                </div>
                <div id="movie-container">
                    <MoviesHeader refetch={refetch} moviesFilters={moviesFilters} setMoviesFilters={setMoviesFilters}/>
                    { renderMovies() }
                </div>
            <Pagination
                setPage={setPage}  
                pageCount={pageCount}
           />
        </div>
    )
}

export default Entertainment
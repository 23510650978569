import { useEffect, useState } from 'react';
import '../../assets/css/default.css';
import '../../assets/css/minibar.min.css';
import '../../assets/css/util.css';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';
import { Link, Outlet } from 'react-router-dom';
import Header from '../../components/Header';
import ScrollToTop from '../../components/ScrollTotop';
import Modal from '../Authentication/components/Modal';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [isMenueActive, setIsMenueActive] = useState(false);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);

     // Disable right-click and certain keyboard shortcuts
     const handleContextMenu = (e) => e.preventDefault();
     const handleKeyDown = (e) => {
       if (e.keyCode === 123 || // F12
           (e.ctrlKey && e.shiftKey && (e.keyCode === 73 || e.keyCode === 74)) || // Ctrl+Shift+I or Ctrl+Shift+J
           (e.ctrlKey && e.keyCode === 85)) { // Ctrl+U
         e.preventDefault();
      }
     };

     document.addEventListener('contextmenu', handleContextMenu);
     document.addEventListener('keydown', handleKeyDown);

     return () => {
       document.removeEventListener('contextmenu', handleContextMenu);
       document.removeEventListener('keydown', handleKeyDown);
     };
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <Modal />
      <ScrollToTop />
      <Header setIsMenueActive={setIsMenueActive} isMenueActive={isMenueActive} />
      <div id="container" className={`${isMenueActive ? 'menue_active' : ''}`}>
        <div className="sec-policy-1">
          <div className="inner">
            <a href="http://localhost:3000/" target="_blank" className="sec-1">
              {/* 접속 차단에 관한 새로운 주소 대피관련 안내 */}
              테스트
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" viewBox="0 0 24 24" data-icon="ic:outline-keyboard-arrow-right" className="iconify iconify--ic">
                <path fill="currentColor" d="M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6z"></path>
              </svg>
            </a>
          </div>
        </div>
        <div className="sec-policy-2">
          <div className="inner">
            <a href="javascript:void(0);" className="sec-1">
              {/* 2023.12.14 공식 도메인 주소가 변경되었습니다 - nunutv.me 참고 바랍니다. */}
              테스트
            </a>
          </div>
        </div>
        <Outlet />
        <Footer />
      </div>
    </>
  );
};

export default App;

import { useState } from "react";
import { Link } from "react-router-dom";

export default function MoviesHeader({refetch, moviesFilters, setMoviesFilters,}){
    const [selectedOption, setSelectedOption] = useState(moviesFilters ? moviesFilters:"필터 선택");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const handleOptionClick = (optionText) => {
        setMoviesFilters(optionText)
        setSelectedOption(optionText);
        setIsDropdownOpen(false);
        refetch()
    };
    return (
        <div className="movie-header">
           <div className="subject line-clamp-1"><h2>전체보기</h2></div>
                    <div className="category ">
                    <div className="multi-nw-dropdown">
                        <div className="multi-select-point" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <span className="multi-nw-select-name">{
                            selectedOption === 'order_by_create_date_desc'? '개봉일 최신순':
                            selectedOption === 'order_by_create_date_asc'? '개봉일 오래된순':
                            selectedOption === 'order_by_viewer_desc'? '조회수 높은순': 
                            selectedOption === 'order_by_fevorate_desc'? '좋아요 높은순': '필터 선택'
                            }</span>
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" viewBox="0 0 24 24" data-icon="ic:sharp-keyboard-arrow-down" className="iconify iconify--ic">
                                <path fill="currentColor" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6l-6-6z"></path>
                            </svg>
                        </div>
                        <div className={`multi-select-list ${isDropdownOpen ? 'nw-show' : ''}`}>
                            <div className="rows selected" onClick={() => handleOptionClick("필터 선택")}>
                                <span className={selectedOption === "필터 선택" ? "on" : ""}>필터 선택</span>
                            </div>
                            <div className="rows">
                                <Link  className={`${selectedOption === 'order_by_create_date_desc'? 'text-red-400':''}`} onClick={() => handleOptionClick("order_by_create_date_desc")}>개봉일 최신순</Link></div>
                            <div className="rows">
                                <Link to="localhost://movies/ceateed-at/sort=asc"  className={`${selectedOption === 'order_by_create_date_asc'? 'text-red-400':''}`} onClick={() => handleOptionClick("order_by_create_date_asc")}>개봉일 오래된순</Link></div>
                            <div className="rows">
                                <Link  className={`${selectedOption === 'order_by_viewer_desc'? 'text-red-400':''}`} onClick={() => handleOptionClick("order_by_viewer_desc")}>조회수 높은순</Link></div>
                            <div className="rows">
                                <Link  className={`${selectedOption === 'order_by_fevorate_desc'? 'text-red-400':''}`} onClick={() => handleOptionClick("order_by_fevorate_desc")}>좋아요 높은순</Link></div>
                        </div>
                    </div>
            </div>
        </div>
    )
}